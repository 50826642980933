import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

import TwitterIcon from "@material-ui/icons/Twitter";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import styles from "./bio.module.scss";

const Bio = ({ style }) => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "pts.jpg" }) {
                childImageSharp {
                    fixed(width: 100, height: 100, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    return (
        <div className={styles.bio} style={style}>
            <div>
                <Image fixed={data?.file?.childImageSharp?.fixed}></Image>
            </div>
            <div>
                <p>
                    Hi, I am <b>Prikshit Tekta</b>. I am a full-stack software
                    engineer.
                </p>

                <div className={styles.social}>
                    <div>
                        <a
                            href="https://twitter.com/itsiprikshit"
                            target="blank"
                        >
                            <TwitterIcon style={{ color: "#1DA1F2" }} />
                        </a>
                    </div>
                    <div>
                        <a
                            href="https://github.com/itsiprikshit"
                            target="blank"
                        >
                            <GitHubIcon style={{ fontSize: "1.4rem" }} />
                        </a>
                    </div>
                    <div>
                        <a
                            href="https://linkedin.com/in/prikshit"
                            target="blank"
                        >
                            <LinkedInIcon style={{ color: "#0077b5" }} />
                        </a>
                    </div>
                    <div>
                        <a href="/resume.pdf" target="blank">
                            <PictureAsPdfIcon style={{ color: "#f35252" }} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bio;
